const initState = {
    isLoading: false,
    data: [],
    error: null,
}

export default function PaymentRequestReducer(state = initState, action) {
    switch (action.type) {
        case "SET_PAYMENT_REQUEST_LIST_LOADING":
            return {
                ...state,
                isLoading: true,
            }
        case "SET_PAYMENT_REQUEST_LIST_DATA":
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            }
        case "SET_PAYMENT_REQUEST_LIST_ERROR":
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            }
        default:
            return state
    }
}