const initState = {
    isLoading: false,
    data: [],
    error: null,
};

const PointBalanceReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_BALANCE_POINT_LOADING":
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case "GET_BALANCE_POINT_DATA":
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case "GET_BALANCE_POINT_ERROR":
            return {
                ...state,
                isLoading: false,
                data: [],
                error: action.payload,
            };
        case "GET_BALANCE_POINT_CLEAR":
            return {
                ...state,
                isLoading: false,
                data: [],
                error: null,
            };
        default:
            return state;
    }
};

export default PointBalanceReducer;