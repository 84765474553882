import React from "react";
import { Typography } from "antd";

const { Title } = Typography;

const TermsCondition = () => {
  return (
    <div>
      <div className="p-2">
        <div style={{ padding: 40 }}>
          <div style={{ textAlign: "center", marginTop: 25, marginBottom: 25 }}>
            <Title level={1}>Terms and Conditions </Title>
          </div>
          <div style={{ paddingLeft: 50, paddingRight: 50 }}>
            <Title level={3}>
              <b>
                {" "}
                ‘FoodGate’ mobile application of the Food Security Division of
                the Presidential Secretariat, Sri Lanka
              </b>
            </Title>
            <Title level={4}>Version 1</Title>
            <p>
              By registering with the FoodGate mobile app (“FoodGate”) owned and
              operated by and on behalf of the Food Security Division of the
              Presidential Secretariat of Sri Lanka( herein after referred to as
              the “Division”), You as the user of the FoodGate (“User”) agrees
              to the following Terms and Conditions set out by the Division;
            </p>
            <ol type="I">
              <li>
                National Identity Card/Passport information is required to
                register with the FoodGate system and obtain its services
              </li>
              <li>
                The User is solely responsible for the usage of the FoodGate.{" "}
              </li>
              <li>
                The User shall be solely liable for any illegal, fraudulent
                activity or misuse related the FoodGate usage.
              </li>
              <li>
                The FoodGate is the exclusive property of Division and the User
                is granted a limited, non-exclusive, non-sub licensable,
                non-transferable access to FoodGate services.
              </li>
              <li>
                The Division reserves the right to recall, amend, update any
                data entered via FoodGate once verified its sole discretion
                without prior notice to the User.
              </li>
              <li>
                User agrees to the Division’s Privacy Notice, which governs the
                use and processing of Data provided by the User to obtain the
                services of FoodGate
              </li>
              <li>
                The Division reserves the rights to review, amend and update and
                the Terms and Conditions from time to time without notice to
                User.
              </li>
              <li>
                These terms and conditions shall be governed by and construed in
                accordance with laws of Sri Lanka.
              </li>
              <li>
                Any dispute arising out of or in connection with these terms and
                conditions, including any question regarding its existence,
                validity, or termination, shall be referred to and finally
                resolved in the Courts of the Democratic Socialist Republic of
                Sri Lanka and the User hereby submit to the exclusive
                jurisdiction of the Courts of the Democratic Socialist Republic
                of Sri Lanka.
              </li>
              <li>
                In the event of any inconsistency/dispute between the English
                terms and conditions and the Sinhala / Tamil terms and
                conditions, the English translation shall prevail to the extent
                of the conflict.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
