const initialState = {
    districtList: [],
    loading: false,
    showMessage: false,
    messageAlert: null,
}

export const districtReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DISTRICT_LIST":
            return {
                ...state,
                loading: true,
            };
        case "GET_DISTRICT_LIST_SUCCESS":
            return {
                ...state,
                districtList: action.payload,
                loading: false,
            };
        case "GET_DISTRICT_LIST_ERROR_SHOW":
            return {
                ...state,
                loading: false,
                showMessage: true,
                messageAlert: action.message,
            };
        case "GET_DISTRICT_LIST_ERROR_HIDE":
            return {
                ...state,
                showMessage: false,
                messageAlert: null,
            };
        case "GET_DISTRICT_LIST_CLEAR":
            return {
                districtList: [],
                loading: false,
                showMessage: false,
                messageAlert: null,
            };
        default:
            return state;
    }
}