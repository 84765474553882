import { getRefreshToken, saveUser } from "session/session";
import qs from "qs";
import { useHistory } from "react-router-dom";

import axios from "axios";

const axiosHttp = axios.create({
  // baseURL: "https://prod-api-h7fggwf0hrfxf8fv.z01.azurefd.net/",
  baseURL:"https://foodgate-dev.azurefd.net/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

axiosHttp.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${JSON.parse(
      localStorage.getItem("token")
    )}`;
    return config;
  },
  (error) => {
    return error;
  }
);

axiosHttp.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const config = error.config;
    if (config?.url !== "/auth/login" && error.response) {
      if (error.response.status === 401 && !config._retry) {
        config._retry = true;
        const history = useHistory();
        try {
          const refToken = getRefreshToken();

          const data = qs.stringify({
            refresh_token: refToken,
          });

          const config = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          };

          const res = await axios.get(
            // `https://prod-api-h7fggwf0hrfxf8fv.z01.azurefd.net/auth/api/v1/auth/refresh`,
            data,
            config
          );

          console.log(res?.data?.data, "response");
          await saveUser(res?.data?.data);
          axiosHttp.defaults.headers.common[
            "Authorization"
          ] = `${`Bearer ${JSON.parse(localStorage.getItem("token"))}`}`;
          return axiosHttp(config);
        } catch (_error) {
          // logoutUser();
          history.push("/");
        }
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default axiosHttp;
