const initState = {
    isLoading: false,
    data: null,
    error: null,
  };
  
  const packScanReducer = (state = initState, action) => {
    switch (action.type) {
      case "SET_PACKR_LOADING":
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case "SET_PACKR_DATA":
        return {
          ...state,
          isLoading: false,
          data: action.payload,
        };
      case "SET_PACKR_ERROR":
        return {
          ...state,
          isLoading: false,
          data: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default packScanReducer;