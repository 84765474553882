const initialState = {
    userTypeList: [],
    loading: false,
    showMessage: false,
    messageAlert: null,
}

export const userTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_USER_TYPE_LIST":
            return {
                ...state,
                loading: true,
            };
        case "GET_USER_TYPE_LIST_SUCCESS":
            return {
                ...state,
                userTypeList: action.payload,
                loading: false,
            };
        case "GET_USER_TYPE_LIST_ERROR_SHOW":
            return {
                ...state,
                loading: false,
                showMessage: true,
                messageAlert: action.message,
            };
        case "GET_USER_TYPE_LIST_ERROR_HIDE":
            return {
                ...state,
                showMessage: false,
                messageAlert: null,
            };
        case "GET_USER_TYPE_LIST_CLEAR":
            return {
                userTypeList: [],
                loading: false,
                showMessage: false,
                messageAlert: null,
            };
        default:
            return state;
    }
}