import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Avatar, message } from "antd";
import { connect } from "react-redux";
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { signOut } from "redux/actions/Auth";
import axiosHttp from "http/axiosHttp";
import { getSession, ripSession } from "session/session";
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import axios from "axios";

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/",
  },

  {
    title: "Account Setting",
    icon: SettingOutlined,
    path: "/",
  },
  {
    title: "Billing",
    icon: ShopOutlined,
    path: "/",
  },
  {
    title: "Help Center",
    icon: QuestionCircleOutlined,
    path: "/",
  },
];

export const NavProfile = ({ signOut }) => {
  const history = useHistory();

  const [user, setUser] = useState({});

  const logOutFunction = () => {
    axiosHttp
      .get("https://prod-api-h7fggwf0hrfxf8fv.z01.azurefd.net/auth/api/v1/auth/logout")
      .then((res) => {
        ripSession();
        history.push("/");
      })
      .catch((err) => {
        message.error("Logout Failed !", 5);
      });
  };

  useEffect(() => {
    try{
    const token = jwtDecode(getSession());
    setUser(token);
    }catch(err){
      
    }
  }, []);

  const profileImg = "/img/avatars/profile.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={40} src={profileImg} />
          <div className="pl-1">
            <h4 className="mb-0">{user?.name}</h4>
            <span className="text-muted">
              Welcome{" "}
              {user?.name_en ? " Admin" : <>Super Admin </>}
            </span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        {/* <Menu>
          <Menu.Item
            key={menuItem.length + 1}
            onClick={(e) => logOutFunction()}
          >
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu> */}
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
