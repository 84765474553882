import React from "react";
import { Typography } from "antd";

const { Title } = Typography;

const PrivacyPolicy = () => {
  return (
    <div className="p-2">
      <div style={{ padding: 40 }}>
        <div style={{ textAlign: "center", marginTop: 25, marginBottom: 25 }}>
          <Title level={1}>Privacy Policy</Title>
        </div>
        <div style={{ paddingLeft: 50, paddingRight: 50 }}>
          <Title level={3}>
            ‘FoodGate’ mobile application of the Food Security Division of the
            Presidential Secretariat, Sri Lanka
          </Title>
          <Title level={4}>Version 1</Title>
          <p>
            The Food Security Division at the Presidential Secretariat of Sri
            Lanka{" "}
            <i>
              <b> (“the Secretariat”, “we” “us” “Our”)</b>{" "}
            </i>
            has initiated an online application named ‘FoodGate’ (collectively
            referred to as the “App”) which intends to gather information
            related to the food security and malnutrition in Sri Lanka. We know
            that you care about how your personal information is used and
            shared. Please read the following privacy policy (the “Policy”)
            carefully, to learn more about our privacy practices. By using this
            App (collectively, the “Service”), you acknowledge that you accept
            the practices and policies outlined in this Policy, and you hereby
            consent for us to collect, use, and share your information in the
            manner and form described in this Privacy Policy below. If you
            choose to use our Service, then you agree to the collection and use
            of information in relation with this policy. The Personal
            Information that we collect are used for providing and improving the
            Service. We will not use or share your information with anyone
            except as described in this Privacy Policy.
          </p>
          <Title level={4}>Interpretation and Definitions</Title>
          <p>
            The words which the initial letter is capitalized have meanings
            defined under the following definitions, and shall have the same
            meaning regardless of whether they appear.
          </p>
          <p>
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which is accessible in the App itself
            unless otherwise defined in this Privacy Policy.
          </p>
          <ul>
            <li>
              <b>Account</b> means a unique account created for you to access
              the App or parts of the App.
            </li>
            <li>
              <b>Country</b> means the Democratic Socialist Republic of Sri
              Lanka.
            </li>
            <li>
              <b>Other Government Parties </b> means relevant Governmental
              Ministries, Departments and Statutory Bodies, Government owned
              Companies and Government owned entities of the Democratic
              Socialist Republic of Sri Lanka.
            </li>
            <li>
              <b>Personal Data</b> means any information that can identify a
              data subject directly or indirectly, by reference to– (a) an
              identifier such as a name, an identification number, financial
              data, location data or an online identifier; or (b) one or more
              factors specific to the physical, physiological, genetic,
              economic, cultural or social identity of that individual or
              natural person.
            </li>
            <li>
              <b>Processing</b> means any operation performed on Personal Data
              including but not limited to collection, storage, preservation,
              alteration, retrieval, disclosure, transmission, making available,
              erasure, destruction of, consultation, alignment, combination, or
              the carrying out of logical or arithmetical operations on Personal
              Data.
            </li>
            <li>
              <b>Third-party service </b> provider means any natural or legal
              person, other than Other Government Parties referred to in the
              above definition, which processes personal data on behalf of the
              Presidential Secretariat
            </li>
            <li>
              <b>Usage Data</b>refers to data collected automatically, either
              generated by the use of the FoodGate System or from the System
              infrastructure itself (for example, the duration of a App usage).
            </li>
            <li>
              <b>You or Your </b>means the individual accessing or using the
              Services, or other person on behalf of which such individual is
              accessing or using the Services, as applicable.
            </li>
          </ul>
          <Title level={4}>Information Collection and Use</Title>
          <p>
            For a better experience while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to your name, phone number, and postal
            address. The information that we collect will be used to contact or
            identify you.
          </p>
          <Title level={4}>Service Providers</Title>
          <p>
            We may employ third-party companies and individuals due to the
            following reasons:
          </p>
          <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          <Title level={4}>Security</Title>
          <p>
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </p>
          <Title level={4}>Changes to This Privacy Policy</Title>

          <p>
            We may update our Privacy Policy from time to time. Thus, we advise
            you to review this Policy periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page. These changes are effective immediately, after they are posted
            on this App.
          </p>
          <Title level={4}>Contact Us</Title>
          <p>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us via foodgate@presidentsoffice.lk.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
