const initState = {
  isLoading: false,
  data: [],
  page: 1,
  error: null,
};

const SimpleListReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true,
        page: 1,
        error: null,
      };
    case "SET_DATA":
      return {
        ...state,
        isLoading: false,
        page: action.payload.page,
        data: action.payload.data,
      };
    case "SET_ERROR":
      return {
        ...state,
        isLoading: false,
        page: 1,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default SimpleListReducer;