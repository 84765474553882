const initState = {
    isLoading: false,
    data: [],
    error: null,
}

export default function QuickPaymentReducer(state = initState, action) {
    switch (action.type) {
        case "SET_QUICK_PAYMENT_REQUEST_LIST_LOADING":
            return {
                ...state,
                isLoading: true,
            }
        case "SET_QUICK_PAYMENT_REQUEST_LIST_DATA":
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            }
        case "SET_QUICK_PAYMENT_REQUEST_LIST_ERROR":
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            }
        case "CLEAR_QUICK_PAYMENT":
            return {
                ...state,
                data: [],
                isLoading: false,
                error: null,
            }
        default:
            return state
    }
}