import { Image, Typography } from "antd";
import EmailSuccessImage from "../../../assets/images/Verification_success.png";
import React from 'react';

const { Title } = Typography;

const EmailSucessPage = () => {
  return (
    <>
      <div className="align-items-center d-flex justify-content-center mt-4 p-4 flex-column">
        <Image
          src={EmailSuccessImage}
          alt="email_success_png_image"
          preview={false}
        />
        <Title level={2} type="success">Account Successfully Verifed.</Title>
      </div>
    </>
  );
};

export default EmailSucessPage;
