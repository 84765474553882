import { Form, Input, Button, Col, Row, message, Modal } from "antd";
import Logo from "../../../assets/images/main_logo.png";
import Background from "../../../assets/images/bg.png";
import React from 'react';

import axios from "axios";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { isSession } from "session/session";
import { AUTH_URL } from "configs/Baseurl";

const LoginPage = () => {
  const [loginForm] = Form.useForm();
  const [resetForm] = Form.useForm();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (isSession() === true) {
      history.push("/app/reports/pack");
    }
    else{
      history.push("/")
    }
  }, []);

  const onLoginbinSubmit = async () => {
    loginForm.validateFields().then((values) => {
      const { username, password } = values;

      const body = {
        email: username,
        password: password,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      axios
        .post(
          `${AUTH_URL}/api/v1/auth/login`,
          // `https://loyaltyapi.orel.com/auth/api/v1/auth/login`,
          body,
          config
        )
        .then((response) => {
          if (response.data.access_token && response.data.refresh_token) {
            localStorage.setItem(
              "token",
              JSON.stringify(response.data.access_token)
            );
            localStorage.setItem(
              "refresh_token",
              JSON.stringify(response.data.refresh_token)
            );
            history.push(`/app/reports/pack`);
          }
        })
        .catch((err) => {
          message.error("Please check your User name and Password", 5);
        });
    });
  };

  const passwordResetFunc = async () => {
    // console.log("first")
    // https://devapi.orelacademy.com/auth/api/v1/auth/forgotpassword
    resetForm.validateFields().then((values) => {
      const { passreset } = values;

      const body = {
        email: passreset,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      axios
        .post(
          // `https://prod-api-h7fggwf0hrfxf8fv.z01.azurefd.net/auth/api/v1/auth/forgotpassword`,
          body,
          config
        )
        .then((response) => {
          message.success("Password reset link sent to your email.", 5);
        })
        .catch((err) => {
          message.error("Sorry ! Something went wrong.", 5);
        });
    });
  };

  const layout = {
    wrapperCol: { span: 15 },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  // const scriptRegex = /^<[^>]*script/;

  // const rules = [
  //   {
  //     pattern: scriptRegex,
  //     message: "Please enter valid value!",
  //   },
  // ];

  const scriptRegex = new RegExp("<[^>]*script");

  // const scriptTagValidationFunc = (e) => {
  //   console.log(scriptRegex.test(e));
  //   if (scriptRegex.test(e)) {
  //     console.log("first");
  //   }
  // };

  return (
    <div>
      <Modal visible={isModalOpen} open={isModalOpen} footer={false}>
        <p className="font-size-lg font-weight-bold">Forget Password?</p>
        <p className="font-weight-lighter">
          Please enter your email to reset your password.
        </p>
        <Form name="reset" initialValues={{ remember: false }} form={resetForm}>
          <div className="font-weight-lighter mb-2"> Email </div>
          <Form form={resetForm}>
            <Form.Item
              name="passreset"
              rules={[
                {
                  required: true,
                  message: "Please enter email!",
                },

                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!scriptRegex.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please check your input value!");
                  },
                }),
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Form>
          <Row justify="end">
            <Button
              className="mr-3"
              type="primary"
              ghost
              style={{
                width: 100,
                borderColor: "#E4002B",
                color: "white",
              }}
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => passwordResetFunc()}
              style={{
                backgroundColor: "#E4002B",
                width: 100,
                borderColor: "#E4002B",
              }}
              type="primary"
            >
              Send
            </Button>
          </Row>
        </Form>
      </Modal>
      <Row>
        <Col md={16} style={{ width: "100%", height: "100vh" }}>
          <img src={Background} alt="background" width="100%" height="100%" />
        </Col>
        <Col md={8}>
          <img
            className="center-login"
            src={Logo}
            alt="food gate"
            style={{
              width: "250px",
              height: "100px",
              marginTop: "40%",
            }}
          />
          <div style={{ textAlign: "center" }}>
            <p style={{ marginTop: 20, fontWeight: 500, fontSize: "1rem" }}>
              Welcome back to Loyalty
            </p>
            <span style={{ fontWeight: 400 }}>
              Please sign in with your username and Password{" "}
            </span>
            <div
              style={{
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                form={loginForm}
                autoComplete="none"
              >
                <Form.Item
                  name="username"
                  {...tailLayout}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!scriptRegex.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Please check your input value!");
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder="User name"
                    autoComplete="off"
                    // onChange={(e) => scriptTagValidationFunc(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  {...tailLayout}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!scriptRegex.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Please check your input value!");
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="password" autoComplete="off" />
                </Form.Item>
                <Row justify="end">
                  <Col sm={4}></Col>
                  <div onClick={showModal}>Forgot your password?</div>
                  <Col sm={4}></Col>
                </Row>
                <Form.Item {...tailLayout}>
                  <Button
                    className="mt-3"
                    type="submit"
                    style={{
                      fontWeight: 500,
                      color: "white",
                      border: "none",
                      backgroundColor: "#E4002B",
                    }}
                    htmlType="submit"
                    block
                    onClick={() => onLoginbinSubmit()}
                  >
                    Sign In
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
