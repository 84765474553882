const initState = {
    data: []
};

const ScannerReducer = (state = initState, action) => {
    switch (action.type) {
        case "ADD_ITEM_TO_TABLE":
            return {
                ...state,
                data: [
                    {
                        id: action.payload.id,
                        serial: action.payload.serial,
                        itemCode: "Processing...",
                        message: "Waiting for response...",
                        type: -1,
                    },
                    ...state.data,
                ]
            };
        case "UPDATE_ITEM_IN_TABLE":
            return {
                ...state,
                data: state.data.map(item =>
                    item.id === action.payload.id
                        ? {
                            ...item,
                            itemCode: action.payload.data?.item_code || "N/A",
                            message: action.payload.data?.message || "Waiting for response...",
                            type: action.payload.data?.type || -1,
                        }
                        : item
                )
            };
        case "RESET_ITEMS_IN_TABLE":
            return {
                ...state,
                data: []
            };
        default:
            return state;
    }
};

export default ScannerReducer;
