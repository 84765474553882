import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import PrivateRoute from "components/route-components/PrivateRoute";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {/* <PrivateRoute
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        /> */}
        <Route
          path={`${APP_PREFIX_PATH}/login`}
          component={lazy(() => import(`./Login/LoginPage`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./Users/index`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/products`}
          component={lazy(() => import(`./Products/index`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/serial`}
          component={lazy(() => import(`./SerialNo/index`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/transaction`}
          component={lazy(() => import(`./PointTransaction/index`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/requests`}
          component={lazy(() => import(`./PaymentRequest/index`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/disputes`}
          component={lazy(() => import(`./Disputes/index`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/edit/user`}
          component={lazy(() => import(`./Users/EditUser`))}
        />
         <PrivateRoute
          path={`${APP_PREFIX_PATH}/webscan`}
          component={lazy(() => import(`./WebScan/index`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/batch`}
          component={lazy(() => import(`./Batch/index`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/view/batch`}
          component={lazy(() => import(`./Batch/viewBatch`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/reports/pack`}
          component={lazy(() => import(`./Reports/PackReports/index`))}
        />
         <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/reports/pack/details`}
          component={lazy(() => import(`./Reports/PackReports/Details`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/reports/birthday`}
          component={lazy(() => import(`./Reports/BirthdayReports/index`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/quick`}
          component={lazy(() => import(`./QuickView/index`))}
        />
        <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/reports/points`}
          component={lazy(() => import(`./Reports/PointReports/index`))}
        />
         <PrivateRoute
          exact
          path={`${APP_PREFIX_PATH}/donations`}
          component={lazy(() => import(`./Donations/index`))}
        />
        <PrivateRoute
        exact
        path={`${APP_PREFIX_PATH}/dns`}
        component={lazy(() => import(`./DNS/index`))}
      />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/reports/pack`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
